import {OrdersController} from "../../orders_controller";

export default class extends OrdersController {
  static targets = ["content", "vehicle_category_ids"]

  connect() {
    if (this.hasContentTarget) {
      const content_url = this.data.get("content-load-url");
      const element_id = this.data.get("activate-element-id");
      const element = document.getElementById(element_id)
      const controller = this;
      element.addEventListener("click", function handler(event) {

        controller._createContent(content_url)
          .catch(error => console.error('Error:', error));


        // element.removeEventListener("click", handler);
      });
    }
  }

  filterTable(event) {
    event.preventDefault();
    const url = this.data.get("content-load-url");
    const params = new URLSearchParams();
    let vehicle_category_ids = super._getSelectCollectionValues(this.vehicle_category_idsTarget);
    if (vehicle_category_ids.length === 0) {
      vehicle_category_ids = 'all'
    }

    params.append("vehicle_category_ids", vehicle_category_ids);
    this._createContent(`${url}?${params.toString()}`)
      .catch(error => console.error('Error:', error));
  }


  // PRIVATE
  async _createContent(url) {
    this.contentTarget.innerHTML = this.spinner;
    let content_response = await fetch(url);
    this.contentTarget.innerHTML = await content_response.text();
  }

  get spinner() {
    return `
      <div class="text-center mt-50">
        <div class="loader"></div>
      </div>
    `;
  }
}