import {OrdersController} from "../orders_controller";

export default class extends OrdersController {
  editTotal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this._openServicesModalForm(url);
  }

  onSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      super._successNotify(data.notification_title, data.notification_message);
      super._hideOrUpdateModalContent(window.layout_modal_md, data);
      const content = super._orderContent;
      if (content) content.innerHTML = data.order_content;
    }
  }

  onError(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      super._errorNotify(data.notification_title, data.notification_message);
      window.layout_modal_md.setContent(data.form_content)
    }
  }
}