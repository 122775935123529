import {OrdersController} from "../../orders_controller";

export default class extends OrdersController {
  static targets = ["delete_link"]

  destroyService(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this._deleteService(url);
  }

  //
  // Private
  //
  _deleteService(url) {
    let confirmMessage = this.data.get("confirm-text");
    if (confirm(confirmMessage)) {
      Rails.ajax({
        url: url,
        type: 'delete',
        success: (data) => {
          if (data) {
            super._successNotify(data.notification_title, data.notification_message);
            const content = super._orderContent;
            if (content) content.innerHTML = data.order_content;
          }
        },
        error: (data) => {
          if (data) {
            super._errorNotify(data.notification_title, data.notification_message);
          }
        }
      })
    }
  }
}