import {OrdersController} from "../orders_controller";

export default class extends OrdersController {
  static targets = [
    // preferred_cars form
    "preferred_car_models_ul",
    "preferred_car_models_count",
    "preferred_car_models_make_id",
    "preferred_car_models_errors",
    "preferred_car_models_errors_text",
    "preferred_car_models_btn",
    "preferred_car_models"
  ];

  editClient(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this._openServicesModalForm(url);
  }

  onSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      super._successNotify(data.notification_title, data.notification_message);
      super._hideOrUpdateModalContent(window.layout_modal_md, data);

      Turbolinks.visit(data.url);
    }
  }

  onError(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      super._errorNotify(data.notification_title, data.notification_message);
      window.layout_modal_md.setContent(data.form_content)
    }
  }

  addSelectedMake(event) {
    event.preventDefault();
    const btn_target = event.currentTarget
    if (!btn_target.classList.contains("disabled")) {
      let form_valid = this.validatePreferredCarForm();
      if (form_valid) {
        this.addEditVehicleMakeRequestToForm();
      }
    }
  }

  removePreferredCarModel(event) {
    event.preventDefault();
    let make_id = event.currentTarget.getAttribute("data-make-id");
    let current_value = this.preferred_car_modelsTarget.value;
    console.log(current_value)
    if (!current_value) { return; }
    current_value = JSON.parse(current_value);
    current_value = current_value.filter((item) => {
      return item.make_id !== make_id;
    });
    current_value = JSON.stringify(current_value);
    this.preferred_car_modelsTarget.value = current_value;
    this.buildEditOrUpdatePreferredCarModelsUl();
  }

  editOrderStatus(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this._openServicesModalForm(url);
  }

  onOrderStatusError(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      super._errorNotify(data.notification_title, data.notification_message);

      // {
      //     "notification_message": "Не сохранено!",
      //     "notification_title": "Уведомление!",
      //     "error": {
      //         "comment": [
      //             "не может быть пустым"
      //         ]
      //     }
      // }
      const error_message = data.error;
      const error_field = document.getElementById('order_status_errors')
      let error_text = "";
      for (const key in error_message) {
        error_text += `${error_message[key].join(", ")}<br>`;
      }
      if (error_field) error_field.innerHTML = error_text;
    }
  }

  onOrderStatusSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      super._successNotify(data.notification_title, data.notification_message);
      super._hideOrUpdateModalContent(window.layout_modal_md, data);

      let table_target = document.getElementById("transport--orders-active-table");
      if (table_target.tabulator) {
        const tabulator = table_target.tabulator;
        console.log(data.row_data)
        tabulator.updateOrAddData([data.row_data]);
      }


    }
  }
}