import BSN from "bootstrap.native";
import {OrdersController} from "../orders_controller";
import Tabulator from "tabulator-tables";

export default class extends OrdersController {
  static targets = ["table", "content"]

  connect() {
    if (this.hasContentTarget) {
      const element_id = this.data.get("activate-element-id");
      const element = document.getElementById(element_id)
      const controller = this;
      element.addEventListener("click", function handler(event) {
        controller._createOrdersTable()
          .catch(error => console.error('Error:', error));
      });
    }

  }

  //
  //            PRIVATE
  //
  async _createOrdersTable() {
    const columns = JSON.parse(this.data.get("table-columns"));
    const url = this.data.get("table-load-url");
    const table_prefix = this.data.get("table-prefix");
    const lang = document.body.dataset.lang;
    const persistenceID = "transport_orders_table_" + lang + table_prefix;
    const table_target = document.getElementById("orders_list_table_" + table_prefix);

    const props = {
      columns: columns,
      persistenceID: persistenceID,
      virtualDomBuffer: 8000,
      index: "id",
      layout: "fitDataStretch",
      headerSort: false,
      height: "100%",
      // pagination
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxProgressiveLoad: "scroll"
    }

    let table_props = Object.assign(super.tabulatorDefaultProps, props)

    table_target.tabulator = await new Tabulator(table_target, table_props);
    await table_target.tabulator.setLocale(super.appLocale);
 }
}